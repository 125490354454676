import cls from 'clsx';
import React from 'react';
import styles from './Typography.module.scss';

export interface TypographyProps {
  color?:
    | 'default'
    | 'primary'
    | 'white'
    | 'warning'
    | 'error'
    | 'success'
    | 'strike_through';
  version?:
    | 'level1'
    | 'level2'
    | 'level3'
    | 'level4'
    | 'level5'
    | 'body1'
    | 'body2'
    | 'body3';
}

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement> & TypographyProps;

export interface LevelTextProps extends HeadingProps {
  component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
}

export const LevelText = React.forwardRef<HTMLHeadingElement, LevelTextProps>(
  function LevelTextBase(
    {
      component,
      children,
      version = 'level1',
      color = 'default',
      className,
      ...rest
    },
    ref
  ) {
    const classes = cls(className, styles[version], styles[color]);
    const Component = component;
    return (
      <Component {...rest} ref={ref} className={classes}>
        {children}
      </Component>
    );
  }
);

export const H1 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H1Base({ version = 'level1', ...rest }, ref) {
    return <LevelText {...rest} version={version} ref={ref} component="h1" />;
  }
);

export const H2 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H2Base({ version = 'level2', ...rest }, ref) {
    return <LevelText {...rest} version={version} ref={ref} component="h2" />;
  }
);

export const H3 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H3Base({ version = 'level3', ...rest }, ref) {
    return <LevelText {...rest} version={version} ref={ref} component="h3" />;
  }
);

export const H4 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H4Base({ version = 'level4', ...rest }, ref) {
    return <LevelText {...rest} version={version} ref={ref} component="h4" />;
  }
);

export const H5 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H5Base({ version = 'level4', ...rest }, ref) {
    return <LevelText {...rest} version={version} ref={ref} component="h5" />;
  }
);

type BodyProps = React.HTMLAttributes<HTMLDivElement> & TypographyProps;

export interface BodyTextProps extends BodyProps {
  component?: 'span' | 'div' | 'p';
}

export const BodyText = React.forwardRef<HTMLDivElement, BodyTextProps>(
  function BodyTextBase(
    {
      component = 'div',
      children,
      version = 'body1',
      color = 'default',
      className,
      ...rest
    },
    ref
  ) {
    const classes = cls(className, styles[version], styles[color]);
    const Component = component;
    return (
      <Component {...rest} ref={ref} className={classes}>
        {children}
      </Component>
    );
  }
);

export const P = React.forwardRef<HTMLParagraphElement, BodyProps>(
  function PBase({ version = 'body1', ...rest }, ref) {
    return <BodyText {...rest} version={version} ref={ref} component="p" />;
  }
);

export const Span = React.forwardRef<HTMLParagraphElement, BodyProps>(
  function SpanBase({ version = 'body1', ...rest }, ref) {
    return <BodyText {...rest} version={version} ref={ref} component="span" />;
  }
);

export const Label = React.forwardRef<
  HTMLLabelElement,
  React.LabelHTMLAttributes<HTMLLabelElement> & TypographyProps
>(function BodyTextBase(
  { children, version = 'body1', color = 'default', className, ...rest },
  ref
) {
  const classes = cls(className, styles[version], styles[color]);
  return (
    <label {...rest} ref={ref} className={classes}>
      {children}
    </label>
  );
});

export type DTTextProps = React.HTMLAttributes<HTMLDataListElement> &
  TypographyProps;

export const DT = React.forwardRef<HTMLDataListElement, DTTextProps>(
  function DTBase({ className, version = 'body1', children, ...rest }, ref) {
    const classes = cls(className, styles[version]);

    return (
      <dt {...rest} ref={ref} className={classes}>
        <strong>{children}</strong>
      </dt>
    );
  }
);

export const DD = React.forwardRef<HTMLDataListElement, DTTextProps>(
  function DTBase({ className, version = 'body1', children, ...rest }, ref) {
    const classes = cls(className, styles[version]);

    return (
      <dd {...rest} ref={ref} className={classes}>
        {children}
      </dd>
    );
  }
);

export type LiProps = React.HTMLAttributes<HTMLLIElement> & TypographyProps;

export const Li = React.forwardRef<HTMLLIElement, LiProps>(function DTBase(
  { className, version = 'body1', color = 'default', children, ...rest },
  ref
) {
  const classes = cls(className, styles[version], styles[color]);

  return (
    <li {...rest} ref={ref} className={classes}>
      {children}
    </li>
  );
});
