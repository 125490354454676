import * as React from 'react';
export function IconAt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 480"
      aria-hidden={true}
      {...props}
    >
      <g fillRule="evenodd">
        <path fill="#fff" d="M640 480H0V0h640z" />
        <path fill="#ed2939" d="M640 480H0V320h640zm0-319.9H0V.1h640z" />
      </g>
    </svg>
  );
}
