import localesConfig from '@/config/config.locales.json';
import { LanguagesData } from '@/types/Languages';

export const languagesData: LanguagesData = {
  da: { label: 'Dansk' },
  de: { label: 'Deutsch' },
  en: { label: 'English' },
  fr: { label: 'Français' },
  it: { label: 'Italiano' },
  nl: { label: 'Nederlands' },
  es: { label: 'Español' },
};

(function ValidateLanguages() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  Object.values(localesConfig).forEach(value => {
    value.locales.forEach(langLocale => {
      const languageCode = langLocale.key.split(/[-_]/)[0];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(languagesData as any)[languageCode]) {
        console.warn(
          'Following language code is missing from the @/data/languages config',
          languageCode
        );
      }
    });
  });
})();
