import React from 'react';
import { Languages } from '@/types/Languages';

export const countries = {
  BE: 'BE',
  DE: 'DE',
  DK: 'DK',
  FR: 'FR',
  GB: 'GB',
  NL: 'NL',
  AT: 'AT',
  ES: 'ES',
} as const;
export type Countries = (typeof countries)[keyof typeof countries];
export type CountriesData = Record<
  Countries,
  {
    languages: Languages[];
    preview: string;
    label: string;
    icon: React.ReactElement;
    hasSpecialOffer?: boolean;
  }
>;
