import localesConfig from '@/config/config.locales.json';
import pageConfig from '@/config/config.page.json';
import { checkForDefaultLocale } from '@/lib/i18n/checkForDefaultLocale';
import { getCountryConfigFromLocale } from '@/lib/i18n/getCountryConfigFromLocale';
import { addSchemeToDomain } from '@/utils/addSchemeToDomain';

export const getServerSideUrlLocation = (locale: string, pathname: string) => {
  if (checkForDefaultLocale(locale!)) {
    return addSchemeToDomain(pageConfig.DEFAULT_DOMAIN);
  }

  if (pathname.includes('[') || pathname.includes('variants')) return;
  const { countryCode } = getCountryConfigFromLocale(locale);
  const { defaultLocale, domain } = localesConfig[countryCode];
  const localeDomain =
    defaultLocale === locale ? domain : `${domain}/${locale}`;
  return `${addSchemeToDomain(localeDomain)}${
    pathname === '/' ? '' : pathname
  }`.split('?')[0];
};
