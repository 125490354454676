import * as React from 'react';
export function IconEsSmall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 900 600"
      aria-hidden={true}
      {...props}
    >
      <path fill="#c60b1e" d="M0 0h900v600H0z" />
      <path fill="#ffc400" d="M0 150h900v300H0z" />
    </svg>
  );
}
