import type { CldOptions } from '@cld-apis/types';
import { buildUrl } from 'cloudinary-build-url';

interface BuildUrlCloudinary extends Partial<CldOptions> {
  src: string;
}

export const buildUrlCloudinary = ({
  src,
  transformations,
}: BuildUrlCloudinary) => {
  return buildUrl(src, {
    cloud: {
      cloudName: 'swapfiets',
      secureDistribution: 'swapfiets-res.cloudinary.com',
      privateCdn: true,
    },
    transformations,
  });
};
