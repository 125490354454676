/**
 * This is a function to grab the encoded Google Analytics cookies.
 * It is used for cross domain tracking from the new website country tlds to the account domain.
 * Read more about the implementation here:
 * https://github.com/analytics-debugger/google-tag-linker
 *
 * TODO: consider installing the library and dropping this code when there is TS support added.
 */

interface ReturnObject {
  _gl: null | string;
}

export function getGa4Linker(): ReturnObject;
export function getGa4Linker(params: {
  returnLinkerParam: false;
}): ReturnObject;
export function getGa4Linker(params: { returnLinkerParam: true }): string;
export function getGa4Linker(params?: { returnLinkerParam: boolean }) {
  const returnObject: ReturnObject = {
    _gl: null,
  };
  let linkerParam = '';

  if (typeof window === 'undefined') {
    return params?.returnLinkerParam ? linkerParam : returnObject;
  }

  // Grab current GA4 Related cookies
  const cookies = getCookies();
  linkerParam = `_gl=${['1', getFingerPrint(cookies), cookies.join('*')].join(
    '*'
  )}`;

  if (params?.returnLinkerParam) {
    return linkerParam;
  }

  returnObject._gl = new URLSearchParams(linkerParam).get('_gl');

  return returnObject;
}

const getCookies = () => {
  const cookies: string[] = [];
  const cookiesList = [
    /^_ga$/, // Main Google Analytics Cookie
    /^_ga_[A-Z,0-9]/, // Google Analytics 4 Session Cookie
    /^FPLC$/, // First Party Linker Cookie > sGTM
  ];
  let _FPLC = undefined;

  `; ${document.cookie}`.split('; ').forEach(ck => {
    const [name, value] = ck.split('=');

    cookiesList.forEach(regex => {
      if (regex.test(name)) {
        // This needs to go at the end
        if (name === 'FPLC') {
          _FPLC = [
            '_fplc',
            Buffer.from(value).toString('base64').replace(/=/g, '.'),
          ].join('*');
        } else {
          if (name.match(/^_ga/)) {
            const newValue = value.match(/G[A-Z]1\.[0-9]\.(.+)/)?.[1];

            cookies.push(
              [
                name,
                Buffer.from(newValue!).toString('base64').replace(/=/g, '.'),
              ].join('*')
            );
          }
        }
      }
    });
  });

  if (_FPLC) cookies.push(_FPLC);

  return cookies;
};

const getFingerPrint = (cookies: string[]) => {
  // Build Finger Print String
  const fingerPrintString = [
    window.navigator.userAgent,
    new Date().getTimezoneOffset(),
    window.navigator.language || window.navigator.language,
    Math.floor(new Date().getTime() / 60 / 1e3) - 0,
    cookies ? cookies.join('*') : '',
  ].join('*');

  // Make a CRC table
  let c;
  const crcTable = [];
  for (let n = 0; n < 256; n++) {
    c = n;
    for (let k = 0; k < 8; k++) {
      c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1;
    }
    crcTable[n] = c;
  }
  // Create a CRC32 Hash
  let crc = 0 ^ -1;
  for (let i = 0; i < fingerPrintString.length; i++) {
    crc =
      (crc >>> 8) ^ crcTable[(crc ^ fingerPrintString.charCodeAt(i)) & 0xff];
  }
  // Convert the CRC32 Hash to Base36 and return the value
  return ((crc ^ -1) >>> 0).toString(36);
};
