import { TransformerOption } from '@cld-apis/types/';
import type { ImageLoaderProps } from 'next/image';
import { buildUrlCloudinary } from '@/lib/cloudinary/buildUrlCloudinary';

const getDefault = (width: number): TransformerOption => ({
  resize: { type: 'scale', width },
});

export const loaderCloudinary = (
  { src, width }: ImageLoaderProps,
  transformations?: TransformerOption
) =>
  buildUrlCloudinary({
    src,
    transformations: !!transformations ? transformations : getDefault(width),
  });
