import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { getServerSideUrlLocation } from '@/utils/getServerSideUrlLocation';

export interface PageHeadProps {
  title?: string;
  description?: string;
  noindex?: boolean;
  nofollow?: boolean;
  alternateList?: { domain: string; hrefLang: string }[];
}

export const PageHead = ({
  title,
  alternateList = [],
  description = '',
  noindex,
  nofollow,
}: PageHeadProps) => {
  const { locale, asPath } = useRouter();
  const canonicalPath = getServerSideUrlLocation(locale!, asPath);
  return (
    <Head>
      <title>{title ?? 'Swapfiets'}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={description} />
      <link rel="shortcut icon" href="/favicon.ico" />
      {asPath.includes('?') && (
        <meta name="robots" content="noindex" key="robots" />
      )}
      {noindex && !nofollow && (
        <meta name="robots" content="noindex" key="robots" />
      )}
      {nofollow && !noindex && (
        <meta name="robots" content="nofollow" key="robots" />
      )}
      {nofollow && noindex && (
        <meta name="robots" content="none" key="robots" />
      )}
      {!nofollow && !noindex && !!canonicalPath && (
        <link rel="canonical" href={canonicalPath}></link>
      )}
      {alternateList.map(el => (
        <link
          key={el.hrefLang}
          rel="alternate"
          hrefLang={el.hrefLang}
          href={el.domain}
        />
      ))}
    </Head>
  );
};
