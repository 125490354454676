import Cookies from 'js-cookie';
import { NextRouter } from 'next/router';
import urlJoin from 'url-join';
import config from '@/config/config.page.json';
import { globalPaths } from '@/data/globalPaths';
import { getGa4Linker } from '@/lib/gtm/getGa4Linker';
import { Countries } from '@/types/Countries';

interface NavigateToNewLocaleParams {
  language: string;
  country: Countries;
  currentCountry?: string;
  router: NextRouter;
}

export const navigateToNewLocale = ({
  language,
  country,
  currentCountry,
  router,
}: NavigateToNewLocaleParams) => {
  const newLocale = `${language}-${country}`;

  Cookies.set(config.NEXT_LOCALE, newLocale, { expires: 365 });
  
  if (country === currentCountry) {
    router.push(router.asPath, router.asPath, { locale: newLocale });
  } else {
    const ga4Id = getGa4Linker({ returnLinkerParam: true });
    const params = ga4Id ? `?${ga4Id}` : '';
    const newPath = urlJoin(
      '/',
      newLocale,
      globalPaths.includes(router.route) ? router.route : '',
      params
    );

    window.location.assign(newPath);
  }
};
